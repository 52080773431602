import React from "react";
import ReactDOM from "react-dom";
import { GlobalStyle } from "./styles/global";
import App from "./App";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { RecoilRoot } from 'recoil';

import "./assets/global.css";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById("root")
);

/*if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}*/

if ('serviceWorker' in navigator) {
  // Limpa todos os caches
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName);
    });
    console.log('Todos os caches foram limpos.');
  }).catch((error) => {
    console.error('Erro ao limpar caches:', error);
  });

  // Opcional: desregistra o service worker
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister().then(() => {
        console.log('Service Worker desregistrado.');
      });
    }
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
//serviceWorkerRegistration.unregister();
/*serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && Object.keys(registration.waiting).length > 0) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      if (
        window.confirm(
          "New version! Ready to update?"
        )
      ) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        //alert("Por favor, atualize a página para carregar a nova versão!")
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
  },
});*/

/*self.addEventListener("activate", function (event) {
  event.waitUntil(
    caches.keys().then(function (cacheNames) {
      return Promise.all(
        cacheNames
          .map(function (cacheName) {
            // completely deregister for ios to get changes too
            console.log("deregistering Serviceworker");
            if ("serviceWorker" in navigator) {
              navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                  registrations.map((r) => {
                    r.unregister();
                  });
                });
              window.location.reload();
            }

            console.log("Removing old cache.", cacheName);
            return caches.delete(cacheName);
          })
      );
    })
  );
});*/
