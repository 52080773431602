import { createContext, useEffect, useState } from "react";
import api, {
  saveLocalStorageToken,
  saveLocalStorageTenantData,
  saveLocalStorageLinesData,
  saveLocalStorageSelectedLineId,
  getLocalStoragedToken,
  getLocalStoragedTenantData,
  getLocalStoragedLinesData,
  getLocalStoragedSelectedLineId,
  getLocalStorageUserData,
  saveLocalStorageUserData,
} from "../../api/axios";
import jwt_decode from "jwt-decode";
import { useRecoilState } from 'recoil';
import { TenantState, LineState, LineListState, MqttState } from '../../state/states';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import UsePusher from "./usePusher";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  //major context
  const [tokenContext, setTokenContext] = useState(getLocalStoragedToken());
  const [userContext, setUserContext] = useState(getLocalStorageUserData());

  const [ lineData, setLineData ] = useRecoilState(LineState);
  const [ linesContext, setLineList ] = useRecoilState(LineListState);
  const [ tenantContext, setTenantData ] = useRecoilState(TenantState);
  const [ mqttContext, setMqttData ] = useRecoilState(MqttState);

  //tenant and lines
  /*const [tenantContext, setTenantContext] = useState(
    //getLocalStoragedTenantData()
    tenantData
  );*/

  /*const [linesContext, setLinesContext] = useState(
    //getLocalStoragedLinesData()
    lineList
  );*/

  //minor context
  const [selectedLineId, setSelectedLineId] = useState(
    //Number(getLocalStoragedSelectedLineId())
    Number(lineData?.id)
  );

  //mqtt context
  /*const [mqttContext, setMqttContext] = useState(
    mqttData
  );*/

  async function login({ email, pass }) {
    try {
      const { data } = await api.post("login", { email, pass });
      if (data.auth) {
        const userCtx = getUserDataFromJwt(data.token);
        saveLocalStorageToken(data.token);
        setUserContext(userCtx);

        try {
          const userData = await api.get(`user/getuserdata/${userCtx.id}`, {
            headers: { "x-access-token": data.token },
          }).then(getuserdata => {
            return getuserdata.data.ret[0];
          }).catch(err => {
            console.log(err);
            throw err;
          });

          if (userData) {
            localStorage.setItem("realtime", JSON.stringify(userData));
            setMqttData(userData);

            await api.get(`tenant/getbyuuid/${userData.tenant}`, {
              headers: { "x-access-token": data.token },
            }).then(getbyuuid => {
              //console.log("passou no uuid", JSON.stringify(getbyuuid.data));
              localStorage.setItem("tenant", JSON.stringify(getbyuuid.data.ret[0]));
              setTenantData(getbyuuid.data.ret[0]);
            }).catch(err => {
              console.log(err);
              throw err;
            });

            if (userData.role > 1) { //Role 2 or 3
              await api.get("line/getbyuserid", {
                headers: { "x-access-token": data.token },
              }).then(getbyuserid => {
                const list = getbyuserid.data.ret.filter(item => {
                  item.number = normalizeNumber(item.number);
                  return item;
                })
                localStorage.setItem("line", JSON.stringify(list[0]));
                localStorage.setItem("lineList", JSON.stringify(list));
                setLineData(list[0]);
                setLineList(list);
              }).catch(err => {
                console.log(err);
                  throw err;
              });
              /*await api.get(`line/getbyid/${userData.lineid}`, {
                headers: { "x-access-token": data.token },
              }).then(getbyid => {
                getbyid.data.ret[0].number = normalizeNumber(getbyid.data.ret[0].number);
                localStorage.setItem("line", JSON.stringify(getbyid.data.ret[0]));
                localStorage.setItem("lineList", JSON.stringify(getbyid.data.ret));
                setLineData(getbyid.data.ret[0]);
                setLineList(getbyid.data.ret);
              }).catch(err => {
                console.log(err);
                throw err;
              });*/
            } else {
              await api.get("line/getall", {
                headers: { "x-access-token": data.token },
              }).then(getbyid => {
                const list = getbyid.data.ret.filter(item => {
                  item.number = normalizeNumber(item.number);
                  return item;
                })
                localStorage.setItem("line", JSON.stringify(list[0]));
                localStorage.setItem("lineList", JSON.stringify(list));
                setLineData(list[0]);
                setLineList(list);
              }).catch(err => {
                console.log(err);
                  throw err;
              });
            }

            UsePusher();

          }
          //setMqttContext(m.data.ret[0]);
          //saveLocalStorageUserData(userCtx);
          //saveLocalStorageTenantData(t.data.ret[0]);
          //saveLocalStorageLinesData(l.data.ret);
          //saveLocalStorageSelectedLineId(l.data.ret[0].id);
        } catch (error) {
          throw error;
        }
      }
      //setTokenContext(data.token);
      //setUserContext(getLocalStorageUserData());
      //setTenantContext(getLocalStoragedTenantData());
      //setLinesContext(getLocalStoragedLinesData());
      //setSelectedLineId(getLocalStoragedSelectedLineId());

      return data;

    } catch (error) {
      throw error;
    }
  }

  function normalizeNumber(number) {
    if (String(number).trim().length === 12) {
        const phone = "(" + String(number).substring(2, 5) + ") " + String(number).substring(5, 8) + "-" + String(number).substring(8);
        return phone;
    } else {
        return number;
    }
  }

  function stopBeams() {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: "d62d18d7-982e-4a80-816f-1f54c66f9688",
    });
    beamsClient.stop()
      .then(() => console.log('Beams stoped'))
      .catch(e => console.error("->>>", e));
    /*beamsClient.clearAllState()
      .then(() => console.log('Beams state has been cleared'))
      .catch(e => console.error('Could not clear Beams state', e));*/
  }

  function logout() {
    stopBeams();

    localStorage.removeItem("token");
    localStorage.removeItem("line");
    localStorage.removeItem("realtime");
    localStorage.removeItem("lineList");
    localStorage.removeItem("tenant");
    //localStorage.removeItem("token_user_loadout");
    //localStorage.removeItem("tenant_data_loadout");
    //localStorage.removeItem("tenant_loadout");
    //localStorage.removeItem("lines_data_loadout");
    //localStorage.removeItem("lineid_selected_loadout");
    setTokenContext(null);
    setUserContext(null);
    //setTenantContext({});
    //setLinesContext({});
    setSelectedLineId(null);
    
  }

  function changeLine(lineId) {
    const line = linesContext.find( item => item.id === lineId);
    //saveLocalStorageSelectedLineId(lineId);
    localStorage.setItem("line", JSON.stringify(line));
    //setSelectedLineId(lineId);
    setLineData(line);
  }

  function activeLine() {
    return linesContext?.find(
      (l) => l.id === lineData.id ?? linesContext[0].id
    );
  }

  function getUserDataFromJwt(jwt = undefined) {
    if (tokenContext) {
      return jwt_decode(tokenContext);
    }
    if (!!jwt) {
      return jwt_decode(jwt);
    }
    return undefined;
  }

  async function postPasswordChange({ oldpass, newpass }) {
    try {
      return await api.post(
        "login/change",
        {
          oldpass,
          newpass,
        },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function getTenantDetails() {
    try {
      return await api.get("tenant/getbyid/" + userContext.id, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function putTenantUpdate(data) {
    try {
      const res = await api.put("tenant/update", data, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
      if (res.data.res) {
        saveLocalStorageTenantData({ ...tenantContext, ...data });
      }
      return res;
    } catch (error) {
      throw error;
    }
  }

  //users
  async function getAllUsers() {
    try {
      return await api.get("user/getall", {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function getAllUsersByLineId() {
    try {
      return await api.get(
        `user/getall/${lineData.id}`,
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function getListUsersByLineId({ query, rows, page, status }) {
    try {
      return await api.post(
        "user/getlist/" + lineData.id,
        {
          query: query ? query : "",
          rows,
          page,
          status: status ? status : "ALL",
        },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function getUserDetailsById(userId) {
    try {
      return await api.get("user/getbyid/" + userId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function getUserDataById(userId) {
    try {
      return await api.get("user/getuserdata/" + userId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function postUserCreate(data) {
    try {
      return await api.post(
        "user/create",
        { ...data, lineid: lineData.id },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function putUserUpdate(userId, data) {
    try {
      return await api.put("user/update/" + userId, data, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function deleteUserRemove(userId) {
    try {
      return await api.get("user/delete/" + userId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  //templates
  async function getAllTemplatesByLineId() {
    try {
      return await api.get(`template/getall/${lineData.id}`, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function getTemplateById(id) {
    try {
      return await api.get(`template/getbyid/${id}`, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function putTemplateUpdateById(templateId, data) {
    try {
      return await api.put("template/update/" + templateId, data, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function postTemplateCreate(data) {
    try {
      return await api.post(
        "template/create",
        { ...data, lineid: lineData.id },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function deleteTemplateById(templateId) {
    try {
      return await api.delete("template/delete/" + templateId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  //groups
  async function getListGroupsByLineId({ page, rows, query }) {
    try {
      return await api.post(
        `group/getlist/${lineData.id}`,
        { page, rows, query: query ? query : "" },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function getAllGroupsByLineId() {
    try {
      return await api.get(
        `group/getall/${lineData.id}`,
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function getGroupById(id) {
    try {
      return await api.get(`group/getbyid/${id}`, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function putGroupUpdateById(groupId, data) {
    try {
      return await api.put("group/update/" + groupId, data, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function postGroupCreate(data) {
    try {
      return await api.post(
        "group/create",
        { ...data, lineid: lineData.id, tenantid: tenantContext.id },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function deleteGroupById(groupId) {
    try {
      return await api.delete("group/delete/" + groupId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  //contacts
  async function getContactById(contactId) {
    try {
      return await api.get(`contact/getbyid/${contactId}`, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function getListContactsByLineId({ page, rows, status, query }) {
    try {
      return await api.post(
        `contact/getlist/${lineData.id}`,
        { page, rows, status, query: query ? query : "" },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function getAllContactsByLineId() {
    try {
      return await api.get(
        `contact/getall/${lineData.id}`,
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function getAllContactsByLike({ like }) {
    try {
      return await api.post(
        `contact/getlike`,
        { like, lineid: lineData.id },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function putContactUpdateById(contactId, data) {
    try {
      return await api.put("contact/update/" + contactId, data, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function postContactCreate(data) {
    try {
      return await api.post(
        "contact/create",
        { ...data, lineid: lineData.id, tenantid: tenantContext.id },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function postContactSave(data) {
    try {
      return await api.post(
        "contact/save",
        { ...data, lineid: lineData.id },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async function deleteContactById(contactId) {
    try {
      return await api.delete("contact/delete/" + contactId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  //Line
  async function getLines() {
    try {
      return await api.get(`line/getall`, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function getLineById(lineId) {
    try {
      return await api.get(`line/getbyid/${lineId}`, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  //chat
  async function getAllChatsByLineId() {
    try {
      return await api.get("chat/getall/" + lineData.id, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function getOneChatById(chatId) {
    try {
      return await api.get("chat/getbyid/" + chatId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function getChatList(params) {
    try {
      return await api.post("chat/getlist", params, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function getChatUnread(params) {
    try {
      return await api.post("chat/getunread", params, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function getChatDetails(params) {
    try {
      return await api.post("chat/getdetails/", params, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function getChatStatus(params) {
    try {
      return await api.post("chat/status", params, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function putChatStatus(params) {
    try {
      return await api.post("chat/status", params, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function getChatCheckByChatAndLineId(chatId, lineId) {
    try {
      return await api.get(`chat/check/${chatId}/line/${lineId}`, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function getChatLike(params) {
    try {
      return await api.post(
        "contact/getlike",
        { ...params, lineid: lineData.id },
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
    } catch (error) {
      throw error;
    }
  }
  async function postChatSend(data) {
    try {
      return await api.post("chat/send/", data, {
        headers: { 
          "content-type": "multipart/form-data",
          "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function postChatMultiple(data) {
    try {
      return await api.post("chat/multiple/", data, {
        headers: { 
          "content-type": "multipart/form-data",
          "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function postChatCreate(data) {
    try {
      return await api.post("chat/create", data, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function putChatUpdate(chatId) {
    try {
      return await api.put("chat/update/" + chatId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }
  async function deleteChatRemove(chatId) {
    try {
      return await api.delete("chat/delete/" + chatId, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function getAllDynamic() {
    try {
      return await api.get("dynamic/getall", {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
      });
    } catch (error) {
      throw error;
    }
  }

  async function prepareMqttContext(userCtx = undefined) {
    try {
      const { data } = await api.get(
        `user/getuserdata/${userContext.id ?? userCtx.id}`,
        {
          headers: {
            "x-access-token": tokenContext ?? getLocalStoragedToken(),
          },
        }
      );
      // setUserData(getuserdata.data.ret[0]);
      // localStorage.setItem("tenant", getuserdata.data.ret[0].tenant);
      // -> setMqttContext(data.ret[0]);
    } catch (error) {
      throw error;
    }
  }

  async function postContactImport(data) {
    try {
      return await api.post("upload/contact", data, {
        headers: { "x-access-token": tokenContext ?? getLocalStoragedToken() },
        "content-type": "multipart/form-data",
      });
    } catch (error) {
      throw error;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        tokenContext,
        userContext,
        tenantContext,
        linesContext,
        mqttContext,
        lineData,
        //auth
        login,
        logout,
        getUserDataFromJwt,
        postPasswordChange,
        //tenant
        getTenantDetails,
        putTenantUpdate,
        //users
        getAllUsers,
        getAllUsersByLineId,
        getListUsersByLineId,
        getUserDetailsById,
        getUserDataById,
        postUserCreate,
        putUserUpdate,
        deleteUserRemove,
        //templates
        postTemplateCreate,
        getAllTemplatesByLineId,
        getTemplateById,
        putTemplateUpdateById,
        deleteTemplateById,
        //lines
        getLines,
        changeLine,
        activeLine,
        getLineById,
        //contacts
        getContactById,
        putContactUpdateById,
        getListContactsByLineId,
        getAllContactsByLineId,
        getAllContactsByLike,
        postContactCreate,
        postContactSave,
        deleteContactById,
        //groups
        getListGroupsByLineId,
        getAllGroupsByLineId,
        getGroupById,
        putGroupUpdateById,
        postGroupCreate,
        deleteGroupById,
        //chat
        getAllChatsByLineId,
        getOneChatById,
        getChatList,
        getChatUnread,
        getChatDetails,
        getChatStatus,
        putChatStatus,
        getChatCheckByChatAndLineId,
        postChatSend,
        postChatMultiple,
        postChatCreate,
        putChatUpdate,
        deleteChatRemove,
        getChatLike,
        //dynamic
        getAllDynamic,
        //Upload
        postContactImport
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
